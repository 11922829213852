/* cc form */
.bordershadowcc2 {
  border: 1px solid #231259; /*rgba(22, 162, 183); */
  padding: 10px;
  margin-top: 10px;
  box-shadow: 3px 3px 5px rgba(22, 162, 183, 0.5);
  /* background-color: #d1cddc; */
  background-color: #ffffff;
  border-radius: 10px;
}


.grid-container-2-cc2 {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 3fr;
  /* background-color: #2196f3; */
  padding: 0px;
  /* box-shadow: 3px 3px 5px rgba(22, 162, 183, 0.5); */
  /* background-color: #d1cddc; */
  background-color: #ffffff;
  /* border-radius: 10px; */
}
/* #e0dee8 */
.grid-container-2-cc {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 3fr;
  /* background-color: #2196f3; */
  padding: 0px;
  /* box-shadow: 3px 3px 5px rgba(22, 162, 183, 0.5); */
  background-color: #e0dee8;
  /* border-radius: 10px; */
}
.grid-item-bold-cc {
  padding: 0px;
  font-size: 10pt;
  text-align: left;
  margin: 1px;
  overflow-x: hidden;
  font-weight: bold;
}
.grid-item-normal-cc {
  /* border-bottom: 1px solid #65598a; */
  /* border-bottom: 1px solid #dddddd; */
  /*  rgba(0, 0, 0, 0.8); */

  font-size: 10pt;
  text-align: left;
  margin: 0px;
  overflow-x: hidden;
}


.card-number {
  width: 180px;
  height: 40px;
  display: inline-block;
  margin: 2px;
}


.card-cvv {
  width: 50px;
  height: 40px;
  display: inline-block;
  margin: 2px;
}


.field {
  background: transparent;
  border: 0;
  outline: none;
  /* width: 50px; */
  /* flex: 1; */
  /* padding-right: 10px; */
  /* padding-left: 10px; */
  padding: 3px;
  cursor: text;
  color: #000;
  font-size: 10pt;
  outline: 1px solid #bdb8cd;
  margin: 10px;
  /* font-weight: 300; */
  /* height: 35px; */
}

.field::-webkit-input-placeholder {
  color: #cfd7e0;
}

.field::-moz-placeholder {
  color: #cfd7e0;
}


.group {
  background: white;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 20px;
}

label {
  position: relative;
  color: #000000;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.group label:not(:last-child) {
  border-bottom: 1px solid #f0f5fa;
}

label > span {
  width: 80px;
  text-align: left;
  margin-right: 30px;
}

.loader {
  display: block;
  font-size: 13px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #666ee8;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: 8px auto;
}


.outcome {
  /* float: left; */
  width: 100%;
  padding-top: 8px;
  min-height: 24px;
  text-align: center;
  border: 0px solid black;
}


.pppform {
  background: #e0dee8;
  z-index: 200;
}



.success,
.error {
  display: inline;
  font-size: 13px;
}

.success {
  color: #666ee8;
}

.success .token {
  font-weight: 500;
  font-size: 13px;
}

/* end ccard styles */
