body {
  background-color: #65598a;
  overscroll-behavior-y: contain;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.landing {
  position: relative;
  /* background: url("./img/showcase.jpg") no-repeat; */
  background: url("./img/steamboat-ski-resort.jpg") no-repeat;
  background-size: cover;

  /* opacity: 0.8; */
  background-position: center;
  /* height: 800px; */
   height: 100vh;  
  padding-top: 50px;
  /* min-height: 700px; */
  /* margin-bottom: -50px; */
}
.landing_good {
  position: relative;
  /* background: url("./img/showcase.jpg") no-repeat; */
  background: url("./img/steamboat-ski-resort.jpg") no-repeat;
  background-size: cover;

  /* opacity: 0.8; */
  background-position: center;
  /* height: 800px; */
   height: 90vh;  
  padding-top: 50px;
  /* min-height: 700px; */
  /* margin-bottom: -50px; */
}

.landing_phone {
  /* position: relative; */
  /* background: url("./img/showcase.jpg") no-repeat; */
  background: url("./img/steamboat-ski-resort.jpg") no-repeat;
  background-size: cover; 

  /* opacity: 0.8; */
  background-position: center;
  height: 400px;
  /* height: 100vh;  */
  padding-top: 50px;
  min-height: 700px;
  /* margin-bottom: -50px; */
}


.landing-page-label {
  background-color: #231259;
  border: 1px solid #ffffff;
  padding: 5px;
  border-radius: 10px;
  /* box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.8); */
}


.landing-page-label2 {
  background-color: #65598a;
  border: 1px solid #ffffff;
  padding: 5px;
  border-radius: 10px;
  /* box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.8); */
}


.btnparking {
  width: 130px;
  margin: 5px;
  background-color: #7b709b;
  /* border-color: #4e417a; */
  border-color: #231259;
  color: #ffffff;
  /* border: 2px solid #231259; */
  /* box-shadow: 3px 3px 6px #231259; */
  box-shadow: 2px 2px 4px #382969;
}
.btnparking:hover {
  background-color: #65598a;
  border-color: #ffffff;
  color: #231259;
}

.normalbuttonwrapper {
  margin: 5px;
}


/* boxes and borders */
.inputbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.innerinputbox {
  flex: 0 0 95%;
  max-width: 95%;
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 4px 4px 6px #231259;
}

.innerinputbox2 {
  /* flex: 0 0 50%; */
  flex: 1;
  max-width: 95%;
  margin-top: 5px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 6px #231259;
  /* box-shadow: 4px 4px 6px #65598a; */
  /* box-shadow: 4px 4px 6px #000000; */
}


.popupdialog {
  position: absolute;
  /* width: 450px; */
  width: 366px;
  /* width: 50%; */
  background-color: #ffffff;
  margin: auto;
  top: 120;
  left: 0;
  right: 0;
  z-index: 99;
  /* border: 2px solid blue; */
  /* padding: 10px; */
}

.popupdialog_phone {
  position: absolute;
  /* width: 320px; */
  width: 86%;
  background-color: #ffffff;
  margin: auto;
  top: 20;
  left: 0;
  right: 0;
  padding: 10px;
  z-index: 99;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* height: 1000px; */
  background: rgba(0, 0, 0, 0.5);
  display: block;
  overflow-y: scroll;
}


.modal-main {
  position: absolute;
  background: #e0dee8;
  width: 346px;
  height: auto;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -0%);
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}


.modal-main-inline {
  background: white; /*rgba(0, 0, 0, 0.3); */
  background: #e0dee8;
  /* width: 30%; */
  width: 346px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}


.modal-main-phone-absolute {
  position: absolute;
  background: #e0dee8;
  /* width: 326px; */
  width: 95%;
  height: auto;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -0%);
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
}


.bordershadow {
  border: 1px solid rgba(22, 162, 183);
  padding: 10px;

  margin-top: 10px;
  /* box-shadow: 3px 3px 5px rgba(22, 162, 183, 0.5); */
  box-shadow: 4px 4px 6px #231259;
  /* box-shadow: 4px 4px 6px #65598a; */

  border-radius: 10px;
}


.bordernoshadow {
  border: 1px solid #231259; /*rgba(22, 162, 183); */
  padding: 10px;
  margin-top: 10px;
  background: #ffffff;
  /* box-shadow: 3px 3px 5px rgba(22, 162, 183, 0.5); */

  border-radius: 10px;
}


.bordershadowsm {
  border: 1px solid rgba(22, 162, 183);
  padding: 5px;
  margin: 5px;
  /* box-shadow: 3px 3px 5px rgba(22, 162, 183, 0.5); */
  box-shadow: 2px 2px 3px #231259;
  border-radius: 5px;
}


.bordernoshadowone {
  border: 1px solid #231259; /*rgba(22, 162, 183); */
  padding: 10px;
  margin-top: 10px;
  /* box-shadow: 3px 3px 5px rgba(22, 162, 183, 0.5); */

  border-radius: 10px;
}

/* credit card styles */

/* labels and decoration  font size*/


.parking_label {
  background-color: #65598a;
  color: white;
  font-size: 12pt;
  padding: 5px;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 10px;
  margin-bottom: 10px;
}


.tenpoint {
  font-size: 10pt;
}

.tacenter {
  text-align: center;
}


.font10pt {
  font-size: 10pt;
  white-space: nowrap;
}

.font10ptb {
  font-size: 10pt;
  white-space: nowrap;
  font-weight: bold;
}

.boldrowtop {
  border-top: 2px solid black;
}

.boldrowtop {
  border-top: 2px solid black;
}



.navbar-custom {
  background-color: #231259;
  border-bottom: 1px solid #65598a;
}
.iconbuttonnav {
  margin-left: 20px;
}


.iconbuttonspan {
  /* margin: 0 10px; */
  margin-right: 20px;
}

/* grids */

.grid-item-normal-a {
  background-color: rgba(255, 255, 255, 0.8);
  /* border-bottom: 1px solid #65598a; */
  border-bottom: 0px solid #dddddd;
  /*  rgba(0, 0, 0, 0.8); */
  padding: 3px;
  font-size: 10pt;
  text-align: left;
  margin: 0px;
  overflow-x: hidden;
}

.grid-item-bold-a {
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 0px solid rgba(0, 0, 0, 0.8);
  padding: 3px;
  font-size: 10pt;
  text-align: left;
  margin: 0px;
  overflow-x: hidden;
  font-weight: bold;
}


.grid-item-normal {
  background-color: rgba(255, 255, 255, 0.8);
  /* border-bottom: 1px solid #65598a; */
  border-bottom: 1px solid #dddddd;
  /*  rgba(0, 0, 0, 0.8); */
  padding: 3px;
  font-size: 10pt;
  text-align: left;
  margin: 1px;
  overflow-x: hidden;
}


.grid-item-normal-warning {
  background-color: rgba(255, 0, 0, 0.3);
  /* border-bottom: 1px solid #65598a; */
  border-bottom: 1px solid #dddddd;
  /*  rgba(0, 0, 0, 0.8); */
  padding: 3px;
  font-size: 10pt;
  text-align: left;
  margin: 1px;
  overflow-x: hidden;
}

.grid-item-bold {
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 0px solid rgba(0, 0, 0, 0.8);
  padding: 0px;
  font-size: 10pt;
  text-align: left;
  margin: 1px;
  overflow-x: hidden;
  font-weight: bold;
}


.grid-item-bold-ul {
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #dddddd;
  padding: 0px;
  font-size: 10pt;
  text-align: left;
  margin: 1px;
  overflow-x: hidden;
  font-weight: bold;
}

.grid-container-2-13 {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: 1fr 3fr;
  /* background-color: #2196f3; */
  padding: 0px;
}

.grid-container-2-a {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 1.5fr;
  /* background-color: #2196f3; */
  padding: 0px;
}

.grid-container-2 {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 1fr;
  /* background-color: #2196f3; */
  padding: 3px;
}


.grid-container-3 {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 2.5fr 2fr 1.5fr;
  /* background-color: #2196f3; */
  padding: 3px;
}


.grid-container-7 {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 2fr 3fr 2fr 2fr 0.5fr 0.5fr 1fr;
  /* background-color: #2196f3; */
  padding: 3px;
}


.grid-container-7a {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 1fr 2fr 2fr 1fr 1fr 1fr;
  /* background-color: #2196f3; */
  padding: 3px;
}

.grid-container-8b {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  /* background-color: #2196f3; */
  padding: 3px;
}




.grid-container-8b-warning {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background-color:  rgba(255, 0, 0, 0.3);
  padding: 3px;
}


.grid-container-9 {
  display: grid;
  grid-column-gap: 0px;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr; */
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr;
  /* background-color: #2196f3; */
  padding: 3px;
}

.grid-container-9a {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
  /* background-color: #2196f3; */
  padding: 3px;
}

.grid-container-9a-warning {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr;
  background-color:  rgba(255, 0, 0, 0.3);
  padding: 3px;
}




.grid-container-9c {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
  /* background-color: #2196f3; */
  padding: 3px;
}


.grid-container-9n {
  display: grid;
  grid-column-gap: 0px;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr; */
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr 1fr 0.5fr 0.5fr;
  /* background-color: #2196f3; */
  padding: 3px;
}




.grid-container-10 {
  display: grid;
  grid-column-gap: 0px;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr; */
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr;
  /* background-color: #2196f3; */
  padding: 3px;
}
.grid-container-10a {
  display: grid;
  grid-column-gap: 0px;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr; */
   grid-template-columns: 1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 0.8fr 0.5fr 0.5fr 0.5fr;
  /* background-color: #2196f3; */
  padding: 3px;
}




/* flex */


.flex_row_center {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  border: 0px solid blue;
  margin: 1px;
  width: 100%;
  flex-wrap: wrap;
}


.flex_row_sb {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  border: 0px solid blue;
  margin: 1px;
  width: 100%;
  flex-wrap: wrap;
}


.flex_item {
  padding: 1px 4px;
  color: white;
}


.flex_item_left {
  padding: 1px 4px;
  color: white;
  text-align: left;
}
.flex_item_right {
  padding: 1px 4px;
  color: white;
  text-align: right;
}


.flex_item_right {
  padding: 1px 4px;
  color: white;
  text-align: right;
}


/* messages and display */


.infomessage {
  font-weight: normal;
  color: black;
  /* color: #ffffff; */
  font-size: 12pt;
  text-align: center;
  position: absolute;
  width: 280px;
  border: 1px solid black;
  padding: 5px;

  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  margin: 40px auto;
  background-color: #7b709b;
  z-index: 10;
  border-radius: 10px;
}


.confirmmessage {
  font-weight: normal;
  color: black;
  /* color: #ffffff; */
  font-size: 12pt;
  text-align: center;
  position: fixed;
  width: 300px;
  border: 1px solid black;
  padding: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  /* margin: 40px auto; */
  background-color: #bdb8cd;
  z-index: 10;
  border-radius: 10px;
}


.loadingmessage {
  font-weight: bold;
  color: #fff;
  font-size: 10pt;
  text-align: center;
  position: absolute;
  width: 260px;
  border: 2px solid #231259;
  border-radius: 10px;
  padding: 12px;
  /* margin-top: 10px; */
  /* box-shadow: 4px 4px 6px #231259; */
  /* box-shadow: 4px 4px 6px #231259, -4px -4px 6px #231259; */
  box-shadow: 0px 0px 6px #231259;
  left: 0;
  right: 0;

  margin: auto;
  background-color: #65598a;
  z-index: 10;
}


.statusmessage {
  font-weight: bold;
  color: #231259;
  font-size: 16px;
  text-align: center;
  position: absolute;
  width: 280px;
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  z-index: 999;
}



.missing_input_data {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.hw_invalid_feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.error-display {
  font-weight: bold;
  color: red;
  font-size: 16px;
}

/* pivot button styles */

.btn-basic {
  width: 120px;
  font-size: 14px;
  /* background-color: rgba(10, 47, 135, 1); */
  background-color: #7b709b;
  color: white;
  cursor: pointer;
  border: 1px solid #231259;
  margin-top: 3px;
  /* padding: 0.375rem 0.75rem; */
  padding: 3px;
  border-radius: 0.25rem;
  /* box-shadow: 2px 2px 4px #382969; */
}

.btn-basic:focus {
  outline: none;
}

.btn-basic:hover {
  /* background-color: rgb(7, 31, 85); */
  background-color:  #231259;
  border-color: white;
  transition: .2 ease-in;
}

.btn-basic:visited {
  /* background-color: rgb(7, 31, 85); */
  background-color:  #ff0000;
}

.btn-basic-selected {
  /* background-color: rgb(7, 31, 85); */
  font-size: 14px;
  width: 120px;
  padding: 3px;
  border-radius: 0.25rem;
  margin-top: 3px;
  background-color:  #231259;
  
  /* background-color:  #7b709b; */
  /* background-color:  #65598A; */
  
  border: 1px solid white;
  color: white;
  cursor: pointer;
 
}
.btn-basic-selected:hover {
  background-color:  #231259;
}

.button_10 {
  font-size: 10px;
}

.button_12 {
  font-size: 12px;
}

.button_14 {
  font-size: 14px;
}

.button_w80 {
  width: 80px;
}
.button_w100 {
  width: 100px;
}
.button_w120 {
  width: 120px;
}



/*

.btnparking {
  width: 130px;
  margin: 5px;
  background-color: #7b709b;
  border-color: #231259;
  color: #ffffff;
  box-shadow: 2px 2px 4px #382969;
}
.btnparking:hover {
  background-color: #65598a;
  border-color: #ffffff;
  color: #231259;
}
*/


/* media queries */
/* */
@media only screen and (max-width: 479px) {
  .tableview {
    display: none;
  }
  .phoneview {
    display: block;
    margin: 2px;
  }
  .innerinputbox {
    flex: 0 0 95%;
  }
  .register {
    flex: 0 0 70%;
  }
  .navbaricons {
    /* float: left; */
    text-align: center;
    margin-top: 10px;
  }
  .navbariconsnormal {
    display: none;
  }
  .navbariconsphone {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 480px) {
  .tableview {
    display: block;
    margin: 2px;
    overflow: hidden;
  }
  .phoneview {
    display: none;
  }
  .innerinputbox {
    flex: 0 0 70%;
  }
  .register {
    flex: 0 0 60%;
  }
  .navbaricons {
    /* float: right; */
    text-align: right;
  }

  .navbariconsnormal {
    display: block;
  }
  .navbariconsphone {
    display: none;
  }
}
